@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Thin.eot');
    src: local('Poppins Thin'), local('Poppins-Thin'),
        url('Poppins-Thin.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Thin.woff2') format('woff2'),
        url('Poppins-Thin.woff') format('woff'),
        url('Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ThinItalic.eot');
    src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'),
        url('Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ThinItalic.woff2') format('woff2'),
        url('Poppins-ThinItalic.woff') format('woff'),
        url('Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraLight.eot');
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
        url('Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraLight.woff2') format('woff2'),
        url('Poppins-ExtraLight.woff') format('woff'),
        url('Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraLightItalic.eot');
    src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'),
        url('Poppins-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('Poppins-ExtraLightItalic.woff') format('woff'),
        url('Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Light.eot');
    src: local('Poppins Light'), local('Poppins-Light'),
        url('Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Light.woff2') format('woff2'),
        url('Poppins-Light.woff') format('woff'),
        url('Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-LightItalic.eot');
    src: local('Poppins Light Italic'), local('Poppins-LightItalic'),
        url('Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-LightItalic.woff2') format('woff2'),
        url('Poppins-LightItalic.woff') format('woff'),
        url('Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.eot');
    src: local('Poppins Regular'), local('Poppins-Regular'),
        url('Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Regular.woff2') format('woff2'),
        url('Poppins-Regular.woff') format('woff'),
        url('Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Italic.eot');
    src: local('Poppins Italic'), local('Poppins-Italic'),
        url('Poppins-Italic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Italic.woff2') format('woff2'),
        url('Poppins-Italic.woff') format('woff'),
        url('Poppins-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Medium.eot');
    src: local('Poppins Medium'), local('Poppins-Medium'),
        url('Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Medium.woff2') format('woff2'),
        url('Poppins-Medium.woff') format('woff'),
        url('Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-MediumItalic.eot');
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
        url('Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-MediumItalic.woff2') format('woff2'),
        url('Poppins-MediumItalic.woff') format('woff'),
        url('Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-SemiBold.eot');
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
        url('Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-SemiBold.woff2') format('woff2'),
        url('Poppins-SemiBold.woff') format('woff'),
        url('Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-SemiBoldItalic.eot');
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
        url('Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('Poppins-SemiBoldItalic.woff') format('woff'),
        url('Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Bold.eot');
    src: local('Poppins Bold'), local('Poppins-Bold'),
        url('Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Bold.woff2') format('woff2'),
        url('Poppins-Bold.woff') format('woff'),
        url('Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-BoldItalic.eot');
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
        url('Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-BoldItalic.woff2') format('woff2'),
        url('Poppins-BoldItalic.woff') format('woff'),
        url('Poppins-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraBold.eot');
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
        url('Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraBold.woff2') format('woff2'),
        url('Poppins-ExtraBold.woff') format('woff'),
        url('Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraBoldItalic.eot');
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
        url('Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('Poppins-ExtraBoldItalic.woff') format('woff'),
        url('Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Black.eot');
    src: local('Poppins Black'), local('Poppins-Black'),
        url('Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Black.woff2') format('woff2'),
        url('Poppins-Black.woff') format('woff'),
        url('Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-BlackItalic.eot');
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
        url('Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-BlackItalic.woff2') format('woff2'),
        url('Poppins-BlackItalic.woff') format('woff'),
        url('Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

